import { render, staticRenderFns } from "./DocConfigCardEmail.vue?vue&type=template&id=85a78dee&scoped=true&"
import script from "./DocConfigCardEmail.vue?vue&type=script&lang=js&"
export * from "./DocConfigCardEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85a78dee",
  null
  
)

export default component.exports