<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>

        <div class="">
          {{item.contact.code}} ({{item.contact.name}})
          <span v-if="item.contact.branch && item.contact.branch !== 'สำนักงานใหญ่'">
            --- {{item.contact.branch}}
          </span>
        </div>
        <div
          class="mt-2"
          v-if="item.children.filter(v => v.type === 'salesCredit').length > 0">
          เครดิต:
          <ul class="my-0">
            <li
              v-for="child in item.children"
              :key="child.id">
              {{child.salesCredit}} วัน
              <span v-if="child.isDefault" class="text-success">
                --- ค่าเริ่มต้น
              </span>
            </li>
          </ul>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <DocConfigFormContact
            :templateType="templateType"
            :docType="docType"
            ref="contact"
            class="col-12"
            select="id"
            v-model="formData.contactId"
            :validations="[
              {text: 'required!', value: $v.formData.contactId.$dirty && !$v.formData.contactId.required}
            ]">
          </DocConfigFormContact>
        </div>

        <template v-if="item.id">
          <div class="form-row">
            <sgv-input-number
              minus
              label="เครดิต (วัน)"
              class="col-6 col-sm-3"
              v-model="salesCreditFormData.salesCredit"
              :validations="[
                {text: 'required!', value: $v.salesCreditFormData.salesCredit.$dirty && !$v.salesCreditFormData.salesCredit.required},
                {text: 'จำนวนมากกว่า 0 หรือ -1', value: $v.salesCreditFormData.salesCredit.$dirty && !$v.salesCreditFormData.salesCredit.minValue}
              ]">
            </sgv-input-number>

            <div class="col-auto align-self-end">
              <button
                type="button"
                class="btn btn-success form-group"
                @click="addSalesCredit">
                เพิ่ม
              </button>
            </div>
          </div>

          <div class="form-group">
            <sgv-input-check
              class="form"
              label="ค่าเริ่มต้น"
              inline
              v-model="salesCreditFormData.isDefault">
            </sgv-input-check>
          </div>

          <div v-if="item.children.filter(v => v.type === 'salesCredit').length > 0">
            เครดิต:
            <ul>
              <li
                v-for="child in item.children"
                :key="child.id">
                <span
                  @click="destroyData(child.id, item.id)"
                  class="pointer mr-1">
                  <fa icon="trash" class="text-danger"></fa>
                </span>
                {{child.salesCredit}} วัน
                <span v-if="child.isDefault" class="text-success">
                  --- ค่าเริ่มต้น
                </span>
              </li>
            </ul>
          </div>
        </template>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact2'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    contact: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        contactId: null
      },
      salesCreditFormData: {
        type: 'salesCredit',
        parentId: this.item.id,
        salesCredit: 0,
        isDefault: false
      }
    }
  },
  validations: {
    formData: {
      contactId: {required}
    },
    salesCreditFormData: {
      salesCredit: {
        required,
        minValue: (value) => {
          return value > 0 || value === -1
        }
      }
    }
  },
  methods: {
    serializeInput (formData) {
      return formData
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$emit('update', {
        id: this.item.id,
        input: this.serializeInput(this.formData),
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    },
    addSalesCredit () {
      this.$v.salesCreditFormData.$touch()
      if (this.$v.salesCreditFormData.$invalid) return

      this.$emit('child', {
        input: this.serializeInput(this.salesCreditFormData),
        cb: () => {
          this.toggle = true
          this.$v.salesCreditFormData.$reset()
        }
      })
    },
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.contactId = this.item.contact.id
          this.$refs.contact.setGroup(this.item.contact.type)
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {
          if (this.contact) {
            this.formData.contactId = this.contact.id
            this.$refs.contact.setGroup(this.contact.type)
          }
        })
      } else {
        this.formData.contactId = null
      }
    }
  },
  created () {
    if (this.isForm) this.toggle = true
  },
  components: {
    DocConfigFormContact
  }
}
</script>

<style lang="css" scoped>
</style>
