import gql from 'graphql-tag'


const detailResponse = `
  id type name parentId
  qty price discount discountPrice totalPrice
  docId doc {id type code name approvedAt closedAt}
  inventoryId inventory {id type code name}
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    manageId manage {id code name}
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const CHECKOUT_ORDER = (templateType) => gql`mutation CHECKOUT_ORDER ($docType: String!, $orderId: Int!, $input: Doc${templateType}CheckoutInput!) {
  order: checkoutDoc${templateType}Order (docType: $docType, orderId: $orderId, input: $input) {
    id docId parentId
  }
}`

export const CHECKOUT_REMAINING = (templateType) => gql`mutation CHECKOUT_REMAINING ($docType: String!, $docId: Int!) {
  order: checkoutRemainingDoc${templateType}Order (docType: $docType, docId: $docId) {
    id docId parentId
  }
}`

export const DESTROY_ORDER_CHILD = (templateType) => gql`mutation DESTROY_ORDER_CHILD ($docType: String!, $orderId: Int!) {
  order: destroyDoc${templateType}OrderChild (docType: $docType, orderId: $orderId) {id docId}
}`

export const WATCH_ORDER_CHILD_CREATED = (templateType) => gql`subscription WATCH_ORDER_CHILD_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderChildCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_CHILD_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_CHILD_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderChildDestroyed (docType: $docType, docId: $docId) {
    id docId parentId
  }
}`
