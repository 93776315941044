<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="mb-2 text-warning">
          {{item.contact.code}} ({{item.contact.name}})
        </div>
        <div class="">
          {{item.inventory.code}} ({{item.inventory.name}})
        </div>
        <div class="pre-line mb-2">
          <small>{{item.name}}</small>
        </div>
        <div
          class="text-info"
          v-for="child in item.junctionChildren.filter(x => x.type === 'serviceAccount')"
          :key="child.id">
          <small>กลุ่มบริการ: {{child.name}}</small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <DocConfigFormContact
            :templateType="templateType"
            :docType="docType"
            ref="contact"
            class="col-12"
            select="id"
            v-model="formData.contactId"
            :types="menu.contactTypes"
            :validations="[
              {text: 'required!', value: $v.formData.contactId.$dirty && !$v.formData.contactId.required}
            ]">
          </DocConfigFormContact>

          <sgv-input-textarea
            class="col-12"
            label="ชื่อ"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-textarea>

          <DocConfigFormInventory
            :templateType="templateType"
            :docType="docType"
            ref="inventory"
            class="col-12"
            select="id"
            v-model="formData.inventoryId"
            :validations="[
              {text: 'required!', value: $v.formData.inventoryId.$dirty && !$v.formData.inventoryId.required}
            ]">
          </DocConfigFormInventory>

          <DocConfigFormServiceAccount
            label="กลุ่มบัญชีบริการ"
            placeholder="ค่าเริ่มต้น..."
            :templateType="templateType"
            :docType="docType"
            class="col-12"
            select="id"
            v-model="formData.serviceAccountId">
          </DocConfigFormServiceAccount>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact2'
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory2'
import DocConfigFormServiceAccount from './DocConfigFormServiceAccount'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    contact: {
      type: Object,
      required: false
    },
    inventory: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        name: '',
        contactId: null,
        inventoryId: null,
        accountId: null,
        serviceAccountId: null
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      contactId: {required},
      inventoryId: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      const obj = {
        ...formData,
        accountId: null,
        junctionChildren: []
      }
      delete obj.serviceAccountId

      if (formData.serviceAccountId) {
        obj.junctionChildren.push({
          tag: 'serviceAccountId',
          childId: formData.serviceAccountId
        })
      }

      return obj
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$emit('update', {
        id: this.item.id,
        input,
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.accountId = this.item.accountId

          if (this.item.contact) {
            this.formData.contactId = this.item.contact.id
            this.$refs.contact.setGroup(this.item.contact.type)
          }

          if (this.item.inventory) {
            this.formData.inventoryId = this.item.inventory.id
            this.$refs.inventory.setGroup(this.item.inventory.type)
          }

          if (this.item.junctionChildren.length === 0) return

          const serviceAccount = this.item.junctionChildren.find(v => v.type === 'serviceAccount')

          if (serviceAccount) this.formData.serviceAccountId = serviceAccount.id
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {
          if (this.contact) {
            this.formData.contactId = this.contact.id
            this.$refs.contact.setGroup(this.contact.type)
          }
          if (this.inventory) {
            this.formData.inventoryId = this.inventory.id
            this.$refs.inventory.setGroup(this.inventory.type)
          }
        })
      } else {
        this.formData.name = ''
        this.formData.accountId = null
        this.formData.contactId = null
        this.formData.inventoryId = null
        this.formData.serviceAccountId = null
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormContact,
    DocConfigFormInventory,
    DocConfigFormServiceAccount
  }
}
</script>

<style lang="css" scoped>
</style>
